.App {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  vertical-align:middle;
  height: 100%;
  width: 100%;
}

.App-logo {
  width:77%; 
  max-width: 777px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
